import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ContactsIcon from "@mui/icons-material/Contacts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import LoginIcon from "@mui/icons-material/Login";
import bappaLogo from "../images/bappa-logo.png";
import monroeLogo from "../images/monroe-white.png";
import { useUserContext } from "../pages/Login/userContext";
import { ADMIN_EMAILS } from "./AdminRoute";

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const navBarMap = {
  default: {
    image: bappaLogo,
    text: "Bappa Cards",
  },
  monroe: {
    image: monroeLogo,
    text: "Monroe College",
  },
};

const Sidebar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navbarData, setNavbarData] = useState(navBarMap.default);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logoutUser } = useUserContext();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    logoutUser();
    navigate("/");
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const paramValue = urlSearchParams.get("group");
    if (paramValue) {
      setNavbarData(navBarMap[paramValue]);
    }
  }, []);

  const menuItems = [
    { text: "Home", icon: <HomeIcon />, path: "/" },
    // { text: "Products", icon: <ShoppingCartIcon />, path: "/products" },
    ...(user
      ? [
          { text: "Manage Profile", icon: <PersonIcon />, path: "/dashboard" },
          { text: "Contacts", icon: <ContactsIcon />, path: "/contacts" },
          ...(ADMIN_EMAILS.includes(user.email)
            ? [
                {
                  text: "Admin",
                  icon: <AdminPanelSettingsIcon />,
                  path: "/admin",
                },
                // {
                //   text: "Analytics",
                //   icon: <AnalyticsIcon />,
                //   path: "/analytics",
                // },
              ]
            : []),
          { text: "Log Out", icon: <ExitToAppIcon />, onClick: handleLogout },
        ]
      : [{ text: "Log In", icon: <LoginIcon />, path: "/log-in" }]),
  ];

  const LogoWithTitle = () => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <img
        src={navbarData?.image}
        alt={navbarData?.text}
        style={{ width: "30px", marginRight: "10px" }}
      />
      <Typography variant="h6" noWrap component="div">
        {navbarData?.text}
      </Typography>
    </Box>
  );

  const drawer = (
    <div>
      {!isMobile && (
        <Box sx={{ p: 2 }}>
          <LogoWithTitle />
        </Box>
      )}
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={item.onClick ? "div" : Link}
            to={item.path}
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              }
              if (isMobile) {
                handleDrawerToggle();
              }
            }}
            selected={location.pathname === item.path}
          >
            <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {isMobile && <LogoWithTitle />}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <StyledDrawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </StyledDrawer>
      </Box>
    </>
  );
};

export default Sidebar;
