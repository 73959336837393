import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Menu,
  MenuItem,
  Box,
  Typography,
  Link,
  IconButton,
  Drawer,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import bappaTap from "../../images/bappa-tap.png";
import bappaLogo from "../../images/bappa-logo.png";
import image6 from "../../images/6.jpg";
import card1 from "../../images/card1.jpg";
import personTap from "../../images/personTap.jpg";
import nfcTap from "../../images/nfcTap.jpg";
import "./Lander.css";
import { motion } from "framer-motion";
import MenuIcon from "@mui/icons-material/Menu";
import { card } from "../../assets/jss/material-dashboard-react";

const Lander = () => {
  const [resourcesAnchor, setResourcesAnchor] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleResourcesClick = (event) => {
    setResourcesAnchor(event.currentTarget);
  };

  const handleResourcesClose = () => {
    setResourcesAnchor(null);
  };

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backdropFilter: isScrolled ? "blur(10px)" : "none",
          transition: "all 0.3s ease",
          borderBottom: isScrolled
            ? "1px solid rgba(255, 255, 255, 0.1)"
            : "none",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              justifyContent: "space-between",
              height: { xs: "64px", md: "80px" },
              transition: "all 0.3s ease",
              px: { xs: 2, sm: 3, md: 4 },
            }}
          >
            {/* Logo */}
            <Link
              href="/"
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 0,
              }}
            >
              <img
                src={bappaLogo}
                alt="BappaCards"
                style={{
                  height: "24px",
                  width: "auto",
                }}
              />
            </Link>

            {/* Center Navigation */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 4,
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <Link
                href="/teams"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                For Teams
              </Link>

              <Link
                href="/products"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                Products
              </Link>

              <Link
                href="/pricing"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                Pricing
              </Link>
            </Box>

            {/* Right Side Buttons */}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                flexGrow: 0,
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Link
                  href="/log-in"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "1rem",
                    fontWeight: 400,
                    opacity: 0.9,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                >
                  Login
                </Link>
                <Button
                  variant="contained"
                  href="https://shop.bappacards.com"
                  sx={{
                    bgcolor: "#dc004e",
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: 400,
                    px: 3,
                    py: 1,
                    borderRadius: "8px",
                    "&:hover": {
                      bgcolor: "#0f0f23",
                    },
                  }}
                >
                  Get Started
                </Button>
              </Box>

              {/* Mobile Menu Button */}
              <IconButton
                sx={{
                  display: { xs: "flex", md: "none" },
                  color: "white",
                }}
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Add Mobile Menu Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        PaperProps={{
          sx: {
            width: "100%",
            bgcolor: "rgba(0, 0, 0, 0.95)",
            backdropFilter: "blur(10px)",
            color: "white",
          },
        }}
      >
        <Box sx={{ p: 4, height: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 4 }}>
            <IconButton
              onClick={() => setMobileMenuOpen(false)}
              sx={{ color: "white" }}
            >
              ✕
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Link
              href="/teams"
              sx={{
                color: "white",
                textDecoration: "none",
                fontSize: "1.5rem",
                fontWeight: 500,
                opacity: 0.9,
                "&:hover": { opacity: 1 },
              }}
            >
              For Teams
            </Link>

            <Link
              href="/products"
              sx={{
                color: "white",
                textDecoration: "none",
                fontSize: "1.5rem",
                fontWeight: 500,
                opacity: 0.9,
                "&:hover": { opacity: 1 },
              }}
            >
              Products
            </Link>

            <Link
              href="/pricing"
              sx={{
                color: "white",
                textDecoration: "none",
                fontSize: "1.5rem",
                fontWeight: 500,
                opacity: 0.9,
                "&:hover": { opacity: 1 },
              }}
            >
              Pricing
            </Link>

            <Box sx={{ my: 3, borderTop: "1px solid rgba(255,255,255,0.1)" }} />

            <Link
              href="/log-in"
              sx={{
                color: "white",
                textDecoration: "none",
                fontSize: "1.5rem",
                fontWeight: 500,
                opacity: 0.9,
                "&:hover": { opacity: 1 },
              }}
            >
              Login
            </Link>

            <Button
              variant="contained"
              href="https://shop.bappacards.com"
              sx={{
                bgcolor: "#dc004e",
                textTransform: "none",
                fontSize: "1.2rem",
                fontWeight: 500,
                py: 1.5,
                borderRadius: "8px",
                "&:hover": {
                  bgcolor: "#0f0f23",
                },
              }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Drawer>

      <Box className="lander-container">
        <Container maxWidth="lg">
          <Box
            className="lander-content"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: { xs: 6, md: 8 },
              pt: { xs: "100px", md: "140px" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Box className="lander-text">
              <Typography
                variant="h1"
                className="lander-heading"
                sx={{
                  fontSize: { xs: "3rem", md: "4rem" },
                  fontWeight: "bold",
                  mb: 3,
                }}
              >
                Make Every Introduction Count
                <span className="heading-dot">.</span>
              </Typography>

              <Typography
                variant="h5"
                className="lander-subheading"
                sx={{
                  color: "white",
                  mb: 4,
                }}
              >
                Effortlessly create, share, and elevate your connections with
                BappaCards—your digital business card for the modern
                professional.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  mb: 4,
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#dc004e",
                    "&:hover": {
                      backgroundColor: "#0f0f23",
                    },
                    padding: "12px 40px",
                    fontSize: "1.2rem",
                  }}
                  onClick={() => {
                    window.location.href =
                      "https://shop.bappacards.com/collections/all";
                  }}
                >
                  Try Now
                </Button>
              </Box>

              <Box className="feature-list" sx={{ display: "flex", gap: 3 }}>
                {[
                  "30-day free trial",
                  "Smart card included",
                  "Free US & Canada shipping",
                ].map((feature) => (
                  <Box
                    key={feature}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      color: "text.secondary",
                    }}
                  >
                    <span className="checkmark">✓</span>
                    {feature}
                  </Box>
                ))}
              </Box>
            </Box>

            <Box className="lander-image">
              <img src={bappaTap} alt="OVOU Digital Business Card Demo" />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Add this section after your existing hero section */}
      <Box
        sx={{
          minHeight: "100vh",
          bgcolor: "white",
          color: "#333",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "2.5rem", md: "3.5rem" },
              fontWeight: "bold",
              mb: 2,
              maxWidth: "900px",
              lineHeight: 1.2,
            }}
          >
            <br />
          </Typography>

          <Box
            sx={{
              position: "relative",
              mt: 8,
              height: "600px", // Adjust based on your needs
              bgcolor: "#f5f5f5", // Background for the image/video section
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                zIndex: 2,
                color: "white",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: "2rem", md: "3rem" },
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Step 1
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "2.5rem", md: "4rem" },
                  fontWeight: "bold",
                }}
              >
                Design Your Perfect Card
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  lineHeight: 1.1,
                  textAlign: { xs: "center", md: "left" },
                  maxWidth: "800px",
                }}
              >
                Start by creating a custom digital card that reflects your style
                and brand.
              </Typography>
            </Box>

            {/* Dark overlay */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: "rgba(0,0,0,0.4)",
                zIndex: 1,
              }}
            />

            {/* Background image/video */}
            <Box
              component="video"
              autoPlay
              muted
              loop
              playsInline
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                filter: "brightness(0.4)", // Darkens the video
              }}
            >
              <source
                src={
                  "https://res.cloudinary.com/dn2gadqqz/video/upload/v1736711728/createCard_ggbg9a.mp4"
                }
                type="video/mp4"
              />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Video Section with Step 2 */}
      <Box
        sx={{
          position: "relative",
          height: { xs: "60vh", md: "100vh" },
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* Video Background */}
        <Box
          component="video"
          autoPlay
          muted
          loop
          playsInline
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: "brightness(0.4)", // Darkens the video
          }}
        >
          <source
            src={
              "https://res.cloudinary.com/dn2gadqqz/video/upload/v1736711728/bappaTap_to0wao.mp4"
            }
            type="video/mp4"
          />
        </Box>

        {/* Pause/Play Button */}
        <Box
          sx={{
            position: "absolute",
            top: "2rem",
            right: "2rem",
            zIndex: 2,
            width: "48px",
            height: "48px",
            borderRadius: "50%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            transition: "all 0.3s ease",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
        >
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            ⏸️
          </motion.div>
        </Box>

        {/* Text Overlay */}
        <Container
          maxWidth="lg"
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            zIndex: 1,
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontSize: { xs: "2rem", md: "3rem" },
                fontWeight: "bold",
                mb: 2,
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Step 2
            </Typography>
            <Typography
              variant="h2"
              sx={{
                color: "white",
                fontSize: { xs: "3rem", md: "5rem" },
                fontWeight: "bold",
                lineHeight: 1.1,
                textAlign: { xs: "center", md: "left" },
                maxWidth: "800px",
              }}
            >
              Share Effortlessly, Connect Instantly
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: "white",
                fontWeight: "bold",
                lineHeight: 1.1,
                textAlign: { xs: "center", md: "left" },
                maxWidth: "800px",
              }}
            >
              Easily share your card with a tap or scan, and make meaningful
              connections wherever you go.
            </Typography>
          </motion.div>
        </Container>
      </Box>

      {/* Features Section */}
      <Box
        sx={{
          py: { xs: 8, md: 16 },
          bgcolor: "white",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
              gap: { xs: 3, md: 4 },
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "3rem", md: "4.5rem" },
                  fontWeight: "bold",
                  mb: 12,
                  maxWidth: "600px",
                  lineHeight: 1.1,
                }}
              >
                Your Own
                <br />
                Personal Relationship Manager (PRM)
              </Typography>
            </motion.div>

            {/* Features List */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
              {[
                {
                  title: "Real-Time Updates",
                  description:
                    "Easily update your profile anytime with a few clicks—no more outdated cards.",
                  image: image6,
                  isActive: true,
                },
                {
                  title: "Stay On-Brand",
                  description:
                    "Customize your digital business card with your logo, brand colors, and design.",
                  image: card1,
                },
                {
                  title: "Exchange Contact",
                  description:
                    "Effortlessly capture new connections and store their details with a simple tap or scan.",
                  image: nfcTap,
                },
                {
                  title: "Export New Contacts",
                  description:
                    "Easily export contacts as a CSV file and seamlessly integrate them into your CRM for efficient follow-ups and management.",
                  image: personTap,
                },
              ].map((feature, index) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderLeft: `2px solid ${
                        feature.isActive ? "#FF5733" : "#E0E0E0"
                      }`,
                      pl: 4,
                      py: 3,
                      transition: "all 0.3s ease",
                      "&:hover": {
                        borderLeft: "2px solid #FF5733",
                        backgroundColor: "rgba(255, 87, 51, 0.02)",
                        transform: "translateX(8px)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "600px",
                        transition: "all 0.3s ease",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "2rem",
                          fontWeight: "bold",
                          mb: 1,
                          transition: "all 0.3s ease",
                        }}
                      >
                        {feature.title}
                      </Typography>
                      {(feature.isActive || feature.description) && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          transition={{ duration: 0.3 }}
                        >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              mb: 2,
                              lineHeight: 1.6,
                            }}
                          >
                            {feature.description}
                          </Typography>
                        </motion.div>
                      )}
                      {feature.isActive && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.2 }}
                        >
                          <Link
                            href="#"
                            sx={{
                              color: "#FF5733",
                              textDecoration: "none",
                              fontWeight: 500,
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              "&:hover": {
                                gap: 1.5,
                              },
                              transition: "all 0.3s ease",
                            }}
                          >
                            Learn More
                            <motion.span
                              whileHover={{ x: 5 }}
                              transition={{ duration: 0.2 }}
                            >
                              →
                            </motion.span>
                          </Link>
                        </motion.div>
                      )}
                    </Box>

                    <Box
                      sx={{
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: "12px",
                        width: "240px",
                        height: "140px",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
                        },
                      }}
                    >
                      <motion.img
                        src={feature.image}
                        alt={feature.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.3 }}
                      />
                    </Box>
                  </Box>
                </motion.div>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Pricing Plans Section */}
      <Box
        sx={{
          py: 16,
          bgcolor: "#f5f5f5",
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "3rem", md: "4.5rem" },
                fontWeight: "bold",
                mb: 8,
                "& .dot": {
                  color: "#FF5733",
                },
              }}
            >
              Plans for Professionals at Every Scale
              <span className="dot">.</span>
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
                gap: 4,
              }}
            >
              {/* Individual Plan */}
              <motion.div whileHover={{ y: -8 }} transition={{ duration: 0.3 }}>
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: 4,
                    p: 4,
                    height: "100%",
                    boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      color: "#FF5733",
                      mb: 3,
                    }}
                  >
                    For Individuals
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      color: "text.secondary",
                      mb: 4,
                      minHeight: "80px",
                    }}
                  >
                    <strong>Your Personal Brand, Perfected </strong> Stand out
                    with a sleek, digital business card that makes sharing your
                    details seamless and memorable. Leave a lasting impression
                    and grow your network effortlessly.
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: "#dc004e",
                      py: 1.5,
                      textTransform: "none",
                      fontSize: "1.1rem",
                      "&:hover": {
                        bgcolor: "#e64a2e",
                      },
                    }}
                    onClick={() => {
                      window.location.href = "https://shop.bappacards.com";
                    }}
                  >
                    Try for free
                  </Button>
                </Box>
              </motion.div>

              {/* Teams Plan */}
              <motion.div whileHover={{ y: -8 }} transition={{ duration: 0.3 }}>
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: 4,
                    p: 4,
                    height: "100%",
                    boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      color: "#8B3DFF", // Purple for Teams
                      mb: 3,
                    }}
                  >
                    For Teams
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      color: "text.secondary",
                      mb: 4,
                      minHeight: "80px",
                    }}
                  >
                    <strong>Equip Your Team to Win</strong>
                    <br /> Empower your team to represent your brand with
                    consistency and impact. Track performance, streamline
                    networking at events, and turn every interaction into an
                    opportunity.
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: "#dc004e",
                      py: 1.5,
                      textTransform: "none",
                      fontSize: "1.1rem",
                      "&:hover": {
                        bgcolor: "#e64a2e",
                      },
                    }}
                    // onClick={() => {
                    //   window.location.href = "https://shop.bappacards.com";
                    // }}
                  >
                    Coming Soon
                  </Button>
                </Box>
              </motion.div>

              {/* Enterprise Plan */}
              <motion.div whileHover={{ y: -8 }} transition={{ duration: 0.3 }}>
                <Box
                  sx={{
                    bgcolor: "#1A1A1A",
                    borderRadius: 4,
                    p: 4,
                    height: "100%",
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      color: "#FFFFFF",
                      mb: 3,
                    }}
                  >
                    For Enterprises
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      color: "rgba(255,255,255,0.7)",
                      mb: 4,
                      minHeight: "80px",
                    }}
                  >
                    <strong>
                      Scalable Solutions for Growing Organizations
                    </strong>
                    <br /> Take control with a secure, customizable platform
                    designed for large organizations. Manage teams, track ROI,
                    and integrate seamlessly with your existing CRM to drive
                    measurable results.
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: "white",
                      color: "#1A1A1A",
                      py: 1.5,
                      textTransform: "none",
                      fontSize: "1.1rem",
                      "&:hover": {
                        bgcolor: "rgba(255,255,255,0.9)",
                      },
                    }}
                  >
                    Coming Soon
                  </Button>
                </Box>
              </motion.div>
            </Box>
          </motion.div>
        </Container>
      </Box>

      <Box
        sx={{
          py: { xs: 8, md: 16 },
          bgcolor: "white",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "2.5rem", md: "3.5rem" },
              fontWeight: "bold",
              mb: 3,
              textAlign: "center",
            }}
          >
            Who Uses Bappa Cards?
          </Typography>

          <Typography
            variant="h6"
            sx={{
              color: "text.secondary",
              textAlign: "center",
              mb: 8,
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            Unlock smarter connections, streamline follow-ups, and drive real
            results with Bappa Cards—designed for professionals at every level.
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
              gap: 4,
              mb: 8,
            }}
          >
            {/* <Box>
              <Box
                component="img"
                src={handshake} // You'll need to import this
                sx={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: 2,
                  mb: 3,
                }}
                alt="Business Networking"
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Business Networking
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  lineHeight: 1.6,
                }}
              >
                <strong>Make Every Introduction Count: </strong> Bappa Cards
                simplify networking by letting you instantly share updated
                contact details, links, and social profiles. Leave a lasting
                impression that goes beyond a name and number, helping you stand
                out in any professional setting.
              </Typography>
            </Box> */}
            {/* 
            <Box>
              <Box
                component="img"
                src={handshake} // You'll need to import this
                sx={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: 2,
                  mb: 3,
                }}
                alt="Sales"
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Sales
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  lineHeight: 1.6,
                }}
              >
                Bappa Cards empower sales teams to connect with prospects
                seamlessly while providing sales managers with real-time
                visibility into performance. Track the number of leads your reps
                generate during industry events and measure engagement
                effortlessly. Share presentations, offers, and resources on the
                spot, ensuring your team stays productive and competitive.
              </Typography>
            </Box> */}

            {/* <Box>
              <Box
                component="img"
                src={handshake} // You'll need to import this
                sx={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: 2,
                  mb: 3,
                }}
                alt="Recruiters"
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Recruiters
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  lineHeight: 1.6,
                }}
              >
                <strong>Turn Introductions Into Talent Leads: </strong> Bappa
                Cards streamline lead collection for recruiters. Capture contact
                information instantly with a scan—no more manual entries or
                missed opportunities. Export all new leads as a CSV file and
                upload them directly to your CRM to follow up.
              </Typography>
            </Box> */}

            {/* <Box>
              <Box
                component="img"
                src={handshake} // You'll need to import this
                sx={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: 2,
                  mb: 3,
                }}
                alt="Executives"
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Executives
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  lineHeight: 1.6,
                }}
              >
                Bappa Metal Cards are more than just digital business
                cards—they’re a statement. With a sleek design, premium weight,
                and unmatched quality comparable to a Centurion or Platinum Amex
                card, these cards command attention at any event. Stand out
                effortlessly, make memorable connections, and showcase your
                leadership with a card that reflects your success and status.
              </Typography>
            </Box> */}

            {/* <Box>
              <Box
                component="img"
                src={handshake} // You'll need to import this
                sx={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: 2,
                  mb: 3,
                }}
                alt="Real Estate"
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Real Estate
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  lineHeight: 1.6,
                }}
              >
                <strong>Reimagine Client Engagement: </strong> Bappa Cards
                transform how realtors connect with clients and showcase
                properties. Share virtual tours, contact details, and property
                highlights instantly. You can track every interaction and turn
                open house visitors into buyers.
              </Typography>
            </Box> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Lander;
