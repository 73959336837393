import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import {
  Box,
  CssBaseline,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import Sidebar from "./components/Sidebar";
import Home from "./pages/HomePage/Home";
import Services from "./pages/Services/Services";
import Products from "./pages/Products/Products";
import Contact from "./pages/ContactUs/Contact";
import ProfilePage from "./pages/Account/Profile";
import Admin from "./pages/Account/Admin";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from "./components/Auth";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import UserProfile from "./pages/Account/UserProfile";
import GroupProfile from "./pages/Account/GroupProfile";
import Contacts from "./pages/Dashboard/Contacts";
import AdminDash from "./pages/Account/AdminDash";
import ResetPassword from "./pages/Login/ResetPassword";
import Analytics from "./pages/Account/Analytics";
import Lander from "./pages/Home/Lander";
import CheckoutForm from "./pages/Home/Checkout";
import LegalPage from "./pages/Home/LegalPage";
import SubscriptionsPage from "./components/Pricing/SubscriptionsPage";

import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss";
import "./assets/demo/demo.css?v=1.3.0";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: ##0f0f23;
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
  }
`;

const theme = createTheme({
  palette: {
    primary: {
      main: "#0f0f23",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f5f5f5",
    },
  },
});

function AppContent() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", overflow: "hidden" }}>
      {![
        "/log-in",
        "/sign-up",
        "/reset-password",
        "/lander",
        "/products",
        "/privacy-notice",
        "/pricing",
        "/",
      ].includes(location.pathname) && <Sidebar />}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "100%",
          maxWidth: "100vw",
          overflowX: "hidden",
          p: { xs: 1, sm: 2 },
          mt: { xs: "56px", sm: "64px" },
        }}
      >
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Lander />} />
          <Route
            path="/services"
            element={
              <PrivateRoute>
                <Services />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/group/:id"
            element={
              <AdminRoute>
                <GroupProfile />
              </AdminRoute>
            }
          />
          <Route
            path="/dash"
            element={
              <AdminRoute>
                <AdminDash />
              </AdminRoute>
            }
          />
          <Route
            path="/contacts"
            element={
              <PrivateRoute>
                <Contacts />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <Admin />
              </AdminRoute>
            }
          />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/products" element={<Products />} />
          <Route path="/log-in" element={<Login />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profile/:id" element={<ProfilePage />} />
          <Route path="*" element={<Lander />} />
          <Route path="/lander" element={<Lander />} />
          <Route path="/checkout" element={<CheckoutForm />} />
          <Route path="/privacy-notice" element={<LegalPage />} />
          <Route path="/pricing" element={<SubscriptionsPage />} />
        </Routes>
      </Box>
    </Box>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <GlobalStyle />
          <CssBaseline />
          <AppContent />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
