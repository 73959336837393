export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "Bappa Preferred",
  headline: "Branded PVC Card",
  description:
    "This is the BappaCards branded PVC card. You CAN NOT upload any custom images to this",
  buttonLabel: "Shop Now",
  imgStart: "",
  img: require("../../images/card1.jpg"),
  alt: "Credit Card",
  linkTo: "https://shop.bappacards.com/products/branded-pvc-card",
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: "Bappa Custom",
  headline: "Fully Customized PVC Card",
  description:
    "Print any image directly to your card. This image MUST be a high-quality image to receive high-quality results.",
  buttonLabel: "Shop Now",
  imgStart: "start",
  img: require("../../images/card2.jpg"),
  alt: "Vault",
  linkTo: "https://shop.bappacards.com/products/fully-customized-pvc-card",
};

export const homeObjThree = {
  primary: true,
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "Bappa Platinum",
  headline: "Engraved Metal Card",
  description:
    "This is a METAL card that's ENGRAVED. You can engrave your name, title, and logo onto this card.",
  buttonLabel: "Shop Now",
  imgStart: "",
  img: require("../../images/card3.jpg"),
  alt: "Vault",
  linkTo: "https://shop.bappacards.com/products/engraved-metal-card",
};
