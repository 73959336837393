import React, { useState, useEffect } from "react";
import { useUserContext } from "../Login/userContext";

import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Box,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  Paper,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Stack,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
  Fab,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CirclePicker } from "react-color";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import ProfilePreview from "./ProfilePreview";
import RegisterCard from "../Dashboard/RegisterCard";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LanguageIcon from "@mui/icons-material/Language";
import * as action from "../../service/action";
import LoadingButton from "@mui/lab/LoadingButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { Slide } from "@mui/material";
import { message } from "antd";
import { ADMIN_EMAILS } from "../../components/AdminRoute";

// Import icons
import instagramIcon from "../../images/instagram-color-icon.png";
import twitterIcon from "../../images/twitter-color-icon.png";
import youtubeIcon from "../../images/youtube-color-icon.png";
import linkedinIcon from "../../images/linkedin-app-icon.png";
import snapchatIcon from "../../images/snapchat-color-icon.png";
import tiktokIcon from "../../images/tiktok-color-icon.png";
import facebookIcon from "../../images/facebook-round-color-icon.png";
import whatsappIcon from "../../images/whatsapp-round-color-icon.png";
import cashappIcon from "../../images/cash-app-icon.png";
import venmoIcon from "../../images/venmo-icon.png";
import paypalIcon from "../../images/paypal-icon.png";

const ManageProfile = ({ userData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { getUser } = useUserContext();

  const [formData, setFormData] = useState({
    cardName: "",
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    phone: "",
    cardTheme: "#4caf50",
    linkColor: "#000000",
    matchLinkIcons: false,
    font: "DEFAULT",
    profilePhoto: undefined,
    coverPhoto: undefined,
    links: userData.links || {},
    socials: {
      website: [{ name: "", url: "" }], // Updated structure for websites
      instagram: [""],
      twitter: [""],
      linkedin: [""],
      snapchat: [""],
      tiktok: [""],
      facebook: [""],
      youtube: [""],
      whatsapp: [""],
      cashapp: [""],
      paypal: [""],
      venmo: [""],
    },
    socialOrder: [
      "website",
      "instagram",
      "twitter",
      "linkedin",
      "snapchat",
      "tiktok",
      "facebook",
      "youtube",
      "whatsapp",
      "cashapp",
      "paypal",
      "venmo",
    ],
  });

  const [profilePhoto, setProfilePhoto] = useState(undefined);
  const [coverPhoto, setCoverPhoto] = useState(undefined);
  const [websiteMappings, setWebsiteMappings] = useState(
    userData.links.websites || [{ url: "", name: "" }]
  );
  const [openRegisterCard, setOpenRegisterCard] = useState(false);
  const [preferences, setPreferences] = useState(userData.links.preferences);
  const [redirectEnabled, setRedirectEnabled] = useState(
    userData.links.custom_redirect != null
  );

  //   const [customRedirect, setCustomRedirect] = useState(
  //     userData.links.custom_redirect
  //   );

  const [redirectLink, setRedirectLink] = useState(
    userData.links.custom_redirect
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [previewOpen, setPreviewOpen] = useState(false);

  const socialIcons = {
    website: <LanguageIcon />, // Use a generic icon for websites
    instagram: instagramIcon,
    twitter: twitterIcon,
    linkedin: linkedinIcon,
    snapchat: snapchatIcon,
    tiktok: tiktokIcon,
    facebook: facebookIcon,
    youtube: youtubeIcon,
    whatsapp: whatsappIcon,
    cashapp: cashappIcon,
    venmo: venmoIcon,
    paypal: paypalIcon,
  };

  useEffect(() => {
    if (userData) {
      setFormData({
        id: userData.profile_id,
        cardName: userData.cardName || "",
        firstName: userData.links.firstname || "",
        lastName: userData.links.lastname || "",
        title: userData.links.title || "",
        email: userData.links.email || "",
        phone: userData.links.phone_number || "",
        cardTheme: userData?.links?.preferences?.card_theme || "#4caf50",
        linkColor: userData?.links?.preferences?.link_color || "#000000",
        matchLinkIcons: userData.matchLinkIcons || false,
        font: userData?.links?.preferences?.font || "DEFAULT",
        profilePhoto: userData.links.profile_photo,
        coverPhoto: userData.links.background_photo,
        links: userData.links || {},
        socials: {
          website: userData.links.websites || [""], // Add websites to socials
          instagram: userData.links.instagram_usernames || [""],
          twitter: userData.links.twitter_usernames || [""],
          linkedin: userData.links.linkedin_urls || [""],
          snapchat: userData.links.snapchat_usernames || [""],
          tiktok: userData.links.tiktok_usernames || [""],
          facebook: userData.links.facebook_urls || [""],
          youtube: userData.links.youtube_urls || [""],
          whatsapp: userData.links.whatsapp_numbers || [""],
          cashapp: userData.links.cashapp_usernames || [""],
          paypal: userData.links.paypal_usernames || [""],
          venmo: userData.links.venmo_usernames || [""],
        },
        socialOrder:
          userData.links.social_order && userData.links.social_order.length > 0
            ? userData.links.social_order
            : [
                "website",
                "instagram",
                "twitter",
                "linkedin",
                "snapchat",
                "tiktok",
                "facebook",
                "youtube",
                "whatsapp",
                "cashapp",
                "paypal",
                "venmo",
              ],
      });
    }
  }, [userData]);

  const handlePhotoUpload = (info, type) => {
    console.log("Upload info:", info);

    if (!info || !info.file) {
      console.error("No file information provided");
      message.error("File upload failed: No file information");
      return;
    }

    const file = info.file;
    console.log("File object:", file);

    if (!file) {
      console.error("No file object found");
      message.error("File upload failed: No file object");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const imageUrl = e.target.result;
      console.log("Image URL:", imageUrl);

      if (type === "profile") {
        setProfilePhoto(imageUrl);
        setFormData((prevData) => {
          console.log("Updating formData with profile photo");
          return { ...prevData, profilePhoto: file };
        });
      } else if (type === "cover") {
        setCoverPhoto(imageUrl);
        setFormData((prevData) => {
          console.log("Updating formData with cover photo");
          return { ...prevData, coverPhoto: file };
        });
      }
    };

    reader.onerror = (error) => {
      console.error("FileReader error:", error);
      message.error("File read failed");
    };

    reader.readAsDataURL(file);
  };

  const handleUpdateProfile = async () => {
    setIsUpdating(true);

    try {
      const data = {
        firstname: formData.firstName,
        lastname: formData.lastName,
        title: formData.title,
        email: formData.email,
        phone_number: formData.phone,
        instagram_usernames: formData.socials.instagram,
        twitter_usernames: formData.socials.twitter,
        snapchat_usernames: formData.socials.snapchat,
        tiktok_usernames: formData.socials.tiktok,
        facebook_urls: formData.socials.facebook,
        youtube_urls: formData.socials.youtube,
        linkedin_urls: formData.socials.linkedin,
        whatsapp_numbers: formData.socials.whatsapp,
        cashapp_usernames: formData.socials.cashapp,
        paypal_usernames: formData.socials.paypal,
        venmo_usernames: formData.socials.venmo,
        websites: formData.websites,
        custom_redirect: redirectLink,
        preferences: formData.preferences,
        social_order: formData.socialOrder,
        preferences: {
          ...preferences,
          card_theme: formData.cardTheme,
          link_color: formData.linkColor,
        },
      };

      console.log("data:", data);

      let status = 200;

      let response = await action.updateProfile(
        await getUser()?.getIdToken(),
        data
      );
      status = response.status;

      console.log("PROFILE PHOTO");
      console.log(formData.profilePhoto);
      console.log("COVER PHOTO");
      console.log(formData.coverPhoto);

      if (profilePhoto) {
        let photoData = {
          photoType: "profile",
          photo: formData.profilePhoto,
        };
        response = await action.updatePhoto(
          await getUser()?.getIdToken(),
          photoData
        );
        status = response.status;
      }

      if (coverPhoto) {
        let photoData = {
          photoType: "background",
          photo: formData.coverPhoto,
        };
        response = await action.updatePhoto(
          await getUser()?.getIdToken(),
          photoData
        );
        status = response.status;
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        message: "Error updating profile. Please try again.",
        severity: "error",
      });
    } finally {
      setSnackbar({
        open: true,
        message: "Profile updated successfully!",
        severity: "success",
      });
      setIsUpdating(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCustomRedirect = (event) => {
    setRedirectEnabled(event.target.checked);

    // if (!event.target.checked) {
    //   setCustomRedirect("");
    // }
  };

  const handleWebsiteMapping = (index, field, value) => {
    const updatedMappings = [...websiteMappings];
    updatedMappings[index][field] = value;
    setWebsiteMappings(updatedMappings);
    setFormData((prevData) => ({
      ...prevData,
      links: { ...prevData.links, websites: updatedMappings },
    }));
  };

  const addMapping = () => {
    const newMapping = { url: "", name: "" };
    setWebsiteMappings([...websiteMappings, newMapping]);
    setFormData((prevData) => ({
      ...prevData,
      links: { ...prevData.links, websites: [...websiteMappings, newMapping] },
    }));
  };

  const removeMapping = (index) => {
    const updatedMappings = websiteMappings.filter((_, i) => i !== index);
    setWebsiteMappings(updatedMappings);
    setFormData((prevData) => ({
      ...prevData,
      links: { ...prevData.links, websites: updatedMappings },
    }));
  };

  const handleColorChange = (color, type) => {
    setFormData({ ...formData, [type]: color.hex });
  };

  const handleSwitchChange = (e) => {
    setFormData({ ...formData, matchLinkIcons: e.target.checked });
  };

  const colorOptions = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
    "#0f0f23",
    "#000000",
  ];

  const handleOpenRegisterCard = () => setOpenRegisterCard(true);
  const handleCloseRegisterCard = () => setOpenRegisterCard(false);

  const handleSocialChange = (platform, index, field, value) => {
    const newSocials = { ...formData.socials };
    if (platform === "website") {
      newSocials[platform][index] = {
        ...newSocials[platform][index],
        [field]: value,
      };
    } else {
      newSocials[platform][index] = value;
    }
    setFormData({ ...formData, socials: newSocials });
  };

  const addSocialField = (platform) => {
    const newSocials = { ...formData.socials };
    if (platform === "website") {
      newSocials[platform] = [...newSocials[platform], { name: "", url: "" }];
    } else {
      newSocials[platform] = [...newSocials[platform], ""];
    }
    setFormData({ ...formData, socials: newSocials });
  };

  const removeSocialField = (platform, index) => {
    const newSocials = { ...formData.socials };
    newSocials[platform] = newSocials[platform].filter((_, i) => i !== index);
    setFormData({ ...formData, socials: newSocials });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newOrder = Array.from(formData.socialOrder);
    const [reorderedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, reorderedItem);

    setFormData({ ...formData, socialOrder: newOrder });
  };

  const handlePreviewOpen = () => setPreviewOpen(true);
  const handlePreviewClose = () => setPreviewOpen(false);

  console.log("Render - profilePhoto:", profilePhoto);
  console.log("Render - formData:", formData);

  useEffect(() => {
    console.log("profilePhoto updated:", profilePhoto);
  }, [profilePhoto]);

  useEffect(() => {
    console.log("formData updated:", formData);
  }, [formData]);

  console.log("redirectLink:", userData.links.custom_redirect);

  return (
    <Container maxWidth="xl" disableGutters={isMobile}>
      <Grid container spacing={isMobile ? 2 : 4}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: isMobile ? 2 : 4 }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
              About
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  multiline
                  rows={2}
                  value={formData.title}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone"
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Profile Images
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" gutterBottom>
                  Profile picture
                </Typography>
                <ImgCrop rotate>
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={() => false}
                    onChange={(info) => handlePhotoUpload(info, "profile")}
                  >
                    {profilePhoto ? (
                      <img
                        src={profilePhoto}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <div>Upload</div>
                    )}
                  </Upload>
                </ImgCrop>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" gutterBottom>
                  Cover photo
                </Typography>
                <ImgCrop rotate aspect={16 / 9}>
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={() => false} // Prevent default upload behavior
                    onChange={(info) => handlePhotoUpload(info, "cover")}
                  >
                    {coverPhoto ? (
                      <img
                        src={coverPhoto}
                        alt="cover"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <div>Upload</div>
                    )}
                  </Upload>
                </ImgCrop>
              </Grid>
            </Grid>

            {ADMIN_EMAILS.includes(userData.email) && (
              <>
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Choose Theme
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      justifyContent: "space-between",
                      mb: 3,
                      gap: 2,
                    }}
                  >
                    <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Card Theme
                      </Typography>
                      <CirclePicker
                        color={formData.cardTheme}
                        onChange={(color) =>
                          handleColorChange(color, "cardTheme")
                        }
                        colors={colorOptions}
                        width="100%"
                      />
                    </Box>
                    <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Link Color
                      </Typography>
                      <CirclePicker
                        color={formData.linkColor}
                        onChange={(color) =>
                          handleColorChange(color, "linkColor")
                        }
                        colors={colorOptions}
                        width="100%"
                      />
                    </Box>
                  </Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.matchLinkIcons}
                        onChange={handleSwitchChange}
                        name="matchLinkIcons"
                      />
                    }
                    label="Match Link Icons to Card Theme"
                  />
                </Box>

                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Choose Font
                  </Typography>
                  <Select
                    fullWidth
                    value={preferences.font}
                    onChange={(e) =>
                      setPreferences({
                        ...preferences,
                        font: e.target.value,
                      })
                    }
                    name="font"
                  >
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="Arial">Arial</MenuItem>
                    <MenuItem value="Times New Roman">Times New Roman</MenuItem>
                    <MenuItem value="Courier New">Courier New</MenuItem>
                    <MenuItem value="Impact">Impact</MenuItem>
                  </Select>
                </Box>
              </>
            )}

            <Accordion sx={{ mt: 4 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Websites and Social Media</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="socialList">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {formData.socialOrder.map((platform, index) => (
                          <Draggable
                            key={platform}
                            draggableId={platform}
                            index={index}
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                sx={{ mb: 3 }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mb: 1,
                                  }}
                                >
                                  <div {...provided.dragHandleProps}>
                                    <DragIndicatorIcon />
                                  </div>
                                  {typeof socialIcons[platform] === "object" ? (
                                    socialIcons[platform]
                                  ) : (
                                    <img
                                      src={socialIcons[platform]}
                                      alt={platform}
                                      style={{
                                        width: 24,
                                        height: 24,
                                        marginRight: 8,
                                        marginLeft: 8,
                                      }}
                                    />
                                  )}
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ ml: 1 }}
                                  >
                                    {platform === "website"
                                      ? "Website"
                                      : platform.charAt(0).toUpperCase() +
                                        platform.slice(1)}
                                  </Typography>
                                </Box>
                                {formData.socials[platform].map(
                                  (value, fieldIndex) => (
                                    <Box
                                      key={`${platform}-${fieldIndex}`}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mb: 1,
                                      }}
                                    >
                                      {platform === "website" ? (
                                        <>
                                          <TextField
                                            sx={{ mr: 1 }}
                                            label={`Name ${fieldIndex + 1}`}
                                            value={value.name}
                                            onChange={(e) =>
                                              handleSocialChange(
                                                platform,
                                                fieldIndex,
                                                "name",
                                                e.target.value
                                              )
                                            }
                                          />
                                          <TextField
                                            sx={{ mr: 1 }}
                                            label={`URL ${fieldIndex + 1}`}
                                            value={value.url}
                                            onChange={(e) =>
                                              handleSocialChange(
                                                platform,
                                                fieldIndex,
                                                "url",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </>
                                      ) : (
                                        <TextField
                                          fullWidth
                                          label={`${platform} ${
                                            platform === "whatsapp"
                                              ? "number"
                                              : platform === "youtube"
                                              ? "url"
                                              : platform === "facebook"
                                              ? "url"
                                              : platform === "linkedin"
                                              ? "url"
                                              : "username"
                                          } ${fieldIndex + 1}`}
                                          value={value}
                                          onChange={(e) =>
                                            handleSocialChange(
                                              platform,
                                              fieldIndex,
                                              null,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      <IconButton
                                        onClick={() =>
                                          removeSocialField(
                                            platform,
                                            fieldIndex
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Box>
                                  )
                                )}
                                <Button
                                  startIcon={<AddIcon />}
                                  onClick={() => addSocialField(platform)}
                                >
                                  Add another{" "}
                                  {platform === "website"
                                    ? "website"
                                    : platform}
                                </Button>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </AccordionDetails>
            </Accordion>

            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 4 }}
              onClick={handleUpdateProfile}
              loading={isUpdating}
              loadingIndicator="Updating..."
            >
              Update Profile
            </LoadingButton>

            <Snackbar
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={snackbar.severity}
                sx={{ width: "100%" }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          {!isMobile && (
            <Box
              sx={{
                position: isMobile ? "static" : "sticky",
                top: 20,
                mt: isMobile ? 2 : 0,
                display: "flex",
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: isMobile ? 2 : 4,
                  width: isMobile ? "100%" : "auto", // Full width on mobile
                  maxWidth: "500px", // Limit maximum width
                }}
              >
                <Typography variant="h6" align="center" gutterBottom>
                  live profile preview
                </Typography>
                <ProfilePreview
                  formData={{
                    ...formData,
                    profilePhoto: profilePhoto || formData.profilePhoto,
                    coverPhoto: coverPhoto || formData.coverPhoto,
                  }}
                  preferences={preferences}
                  redirectEnabled={redirectEnabled}
                  redirectLink={redirectLink}
                />
              </Paper>
            </Box>
          )}
          <Box
            sx={{
              position: isMobile ? "static" : "sticky",
              top: 20,
              mt: isMobile ? 2 : 0,
            }}
          >
            <Paper elevation={3} sx={{ p: isMobile ? 2 : 4, mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                sx={{ mb: 2 }}
                onClick={handleOpenRegisterCard}
              >
                Register Card
              </Button>
              <RegisterCard
                open={openRegisterCard}
                handleClose={handleCloseRegisterCard}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => {
                  window.location.href = `/profile/${formData.id}`;
                }}
              >
                View Profile
              </Button>
              <Stack
                spacing={1}
                direction="row"
                sx={{ marginBottom: 4 }}
                style={{ justifyContent: "center" }}
              >
                <Switch
                  checked={preferences.showSaveContactButton}
                  onChange={(e) =>
                    setPreferences({
                      ...preferences,
                      showSaveContactButton: e.target.checked,
                    })
                  }
                />{" "}
                Display Save Contact Button
                <Switch
                  checked={preferences.showExchangeContactButton}
                  onChange={(e) =>
                    setPreferences({
                      ...preferences,
                      showExchangeContactButton: e.target.checked,
                    })
                  }
                />{" "}
                Display Exchange Contact Button
              </Stack>
              <Switch
                checked={redirectEnabled}
                onChange={handleCustomRedirect}
              />{" "}
              Custom Redirect
              {redirectEnabled && (
                <TextField
                  fullWidth
                  label="Redirect Link"
                  value={redirectLink}
                  onChange={(e) => setRedirectLink(e.target.value)}
                  margin="normal"
                />
              )}
            </Paper>
          </Box>
        </Grid>
      </Grid>

      {isMobile && (
        <>
          <Fab
            color="primary"
            aria-label="preview"
            onClick={handlePreviewOpen}
            sx={{
              position: "fixed",
              bottom: theme.spacing(2),
              right: theme.spacing(2),
            }}
          >
            <VisibilityIcon />
          </Fab>

          <Dialog
            fullScreen
            open={previewOpen}
            onClose={handlePreviewClose}
            TransitionComponent={Transition}
          >
            <DialogTitle>
              Profile Preview
              <IconButton
                aria-label="close"
                onClick={handlePreviewClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <ProfilePreview
                formData={{
                  ...formData,
                  profilePhoto: profilePhoto || formData.profilePhoto,
                  coverPhoto: coverPhoto || formData.coverPhoto,
                }}
                preferences={preferences}
                redirectEnabled={redirectEnabled}
                redirectLink={redirectLink}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Container>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ManageProfile;
